/* eslint-disable react/prop-types */
import React from 'react'
import { graphql } from 'gatsby'
import Blogpage from 'routes/blog'

// Meta Component
import Meta from 'components/Meta/Meta'

// Blogpage Route
export default Blogpage

// Gatsby SEO Head
export function Head({ data }) {
  return <Meta tags={data.page.seoMetaTags.tags} />
}

export const query = graphql`
  query BlogQuery {
    page: datoCmsPage(slug: { eq: "blog" }) {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      title
      content
    }
    posts: allDatoCmsArticle(
      limit: 42
      filter: { isHiddenInListings: { ne: true } }
      sort: { fields: [date], order: DESC }
    ) {
      edges {
        node {
          id
          date(formatString: "MMMM D, YYYY")
          title
          slug
          excerpt
          featuredMedia {
            gatsbyImageData(
              placeholder: BLURRED
              imgixParams: {
                fit: "crop"
                ar: "3:2"
                fm: "jpg"
                auto: "compress"
                duotone: "f99f53,FA8072"
                duotoneAlpha: 6
              }
            )
          }
          author {
            name
            slug
            mainImage {
              gatsbyImageData(
                placeholder: NONE
                imgixParams: {
                  fm: "jpg"
                  auto: "compress"
                  fit: "facearea"
                  facepad: 3.0
                  maxW: 200
                }
              )
              url
            }
          }
          categories {
            name
            slug
          }
        }
      }
    }
  }
`
